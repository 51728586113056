/**
 ** Created By liuqi on 2020-02-14
 */
import React from 'react'
import {get} from '../utils/apis';
import config from '../../config/config';
import {isInWindow, getTerminalType} from "../utils/tools";

export default class DownloadApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false
    };
  }
  componentWillMount() {
    if (isInWindow()) {
      const ua = navigator.userAgent.toLowerCase();
      if ((ua.includes('micromessenger') && (getTerminalType() === 'yd')) || ua.includes('alipayclient') || (!ua.includes('micromessenger') && ua.includes('qq') && !ua.includes('mqqbrowser'))) {
        this.setState({
          isShow: true
        });
      } else {
        this.getAppDownloadInfo().finally();
      }
    }
  }

  async getAppDownloadInfo() {
    const result = await get(`${config.url.hook}/downloadApp`);
    if (result.code === 0) {
      window.location.href = result.data.apkUrl;
    }
  }

  render() {
    return (
      <div>
        {
          this.state.isShow && (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.6)'
            }}>
              <div style={{color: '#FFF', textAlign: 'center'}}>
                <p>点击右上角三个点</p>
                <p>选择在浏览器中打开 即可下载app</p>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}
